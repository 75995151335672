import { useQuery } from '@tanstack/react-query';
import { getCompanies } from '../../api/requests';

export const useCompanies = () => {
  const { data: companies, isLoading } = useQuery({
    queryKey: ['companies'],
    queryFn: getCompanies,
  });

  return { companies, isLoading };
};
