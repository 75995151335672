import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { getRatingById } from '../../api/requests';
import { Heading } from '../../components/Heading';
import { RatingsESGTable } from './RatingsESGTable';
import { RatingsEthicsTable } from './RatingsEthicsTable';

export const Rating = () => {
  const { ratingId } = useParams();

  const { data: rating } = useQuery({
    queryKey: ['rating', ratingId],
    queryFn: () => getRatingById(Number(ratingId)),
    enabled: !!ratingId,
  });

  return (
    <div>
      <Heading className="mb-6">{rating?.rating_name}</Heading>
      <div className="mb-10">{rating?.rating_description}</div>
      {rating && rating.esg_rating_tab_id ? <RatingsESGTable rating={rating} /> : null}
      {rating && rating.ethics_rating_tab_id ? <RatingsEthicsTable rating={rating} /> : null}
    </div>
  );
};
