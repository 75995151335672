import { useQuery } from '@tanstack/react-query';
import { useMemo, type ReactNode } from 'react';
import { Chart, type ChartOptions } from 'react-charts';
import { getESGRatingsByInn, getReuterModels } from '../../api/requests';
import {
  type GeneralRating,
  type LetterData,
  type RatingESG,
  type Reuter,
  type ReuterModel,
  type YearRatingESGTab,
} from '../../api/types';
import { Heading } from '../../components/Heading';
import { Loader } from '../../components/Loader/Loader';
import { Table, type RowCellData, type TableProps } from '../../components/Table/Table';
import { useGeneralRatings } from '../../hooks/api/useGeneralRatings';
import { useLettersData } from '../../hooks/api/useLettersData';
import { useReuters } from '../../hooks/api/useReuters';

interface CompanyESGDataTableProps {
  ratingsEsg?: RatingESG[];
  reuters?: Reuter[];
  reuterModels?: ReuterModel[];
}

export const CompanyESGDataTable = ({
  ratingsEsg = [],
  reuterModels = [],
  reuters = [],
}: CompanyESGDataTableProps) => {
  const lettersData = useLettersData();

  const reuterModelsMap = reuterModels.reduce((acc, model) => {
    if (model.reuter_id) {
      if (!acc[model.reuter_id]) {
        acc[model.reuter_id] = [];
      }
      acc[model.reuter_id].push(model);
    }
    return acc;
  }, {} as Record<number, ReuterModel[]>);

  const years = Array.from(new Set(ratingsEsg.map((rating) => rating.year))).sort(
    (a, b) => (b || 0) - (a || 0),
  );
  console.log({ reuterModelsMap });
  return (
    <table className="border-2 border-darkGray border-opacity-60 w-full whitespace-pre-wrap">
      <thead className="text-secondary-text">
        <tr className="border-b border-darkGray border-opacity-20">
          <td className="py-2 px-3">Рейтер</td>
          {reuters.map((reuter) => (
            <td
              align="center"
              className="py-2 px-3 border-2 border-darkGray border-b-0 border-opacity-60"
              key={reuter.id}
              colSpan={
                reuterModelsMap[reuter.id || 0]
                  ?.map((_) => _.fields.length)
                  .reduce((accumulator, currentValue) => accumulator + currentValue, 0) || 1
              }
            >
              {reuter.reuter_name}
            </td>
          ))}
        </tr>
        <tr className="border-b border-darkGray border-opacity-20">
          <td className="py-2 px-3">Модель</td>
          {reuters.map((reuter) => {
            const filteredReuterModels = reuterModelsMap[reuter.id || 0];
            return filteredReuterModels?.length ? (
              filteredReuterModels.map((model, i) => (
                <td
                  className={
                    'py-2 px-3 ' +
                    'border-l-2 border-darkGray border-opacity-60 ' +
                    (i !== 0 ? '!border-l !border-opacity-20' : '')
                  }
                  align="center"
                  key={model.id}
                  colSpan={model.fields?.length}
                >
                  {model.rating_model_name}
                </td>
              ))
            ) : (
              <td className="border-x-2 border-darkGray border-opacity-60"></td>
            );
          })}
        </tr>
        <tr className="border-b border-darkGray border-opacity-20">
          <td className="py-2 px-3">Оценка</td>
          {reuters.map((reuter) => {
            const filteredReuterModels = reuterModelsMap[reuter.id || 0];
            return filteredReuterModels?.flatMap((model, modelIndex) => {
              return model?.fields?.map((field, fieldIndex) => {
                const isFirstField = modelIndex === 0 && fieldIndex === 0;

                return (
                  <td
                    key={`${model.id}-${field.name}`}
                    align="center"
                    className={
                      'py-2 px-3 ' +
                      (isFirstField
                        ? 'border-l-2 border-darkGray border-opacity-60'
                        : 'border-l border-darkGray border-opacity-20')
                    }
                  >
                    {field.name}
                  </td>
                );
              });
            });
          })}
        </tr>
      </thead>
      <tbody>
        {years.map((year) => (
          <tr className="border-b border-darkGray border-opacity-20" key={year}>
            <td className="py-2 px-3" align="center">
              {year}
            </td>
            {reuters.map((reuter) =>
              reuterModelsMap[reuter.id || 0]?.map((model, modelIndex) =>
                model.fields.map((field, fieldIndex) => {
                  const isFirstField = modelIndex === 0 && fieldIndex === 0;
                  const rating = ratingsEsg.find(
                    (rating) =>
                      rating.year === year &&
                      rating.reuter_id === reuter.id &&
                      rating.year_rating_esg_tab,
                  );
                  const ratingLetter =
                    rating?.year_rating_esg_tab?.[field.field as keyof YearRatingESGTab];
                  const letterData = lettersData?.find((_) => _.letter_esg === ratingLetter);

                  return (
                    <td
                      key={`${year}-${model.id}-${field.name}`}
                      className={
                        'py-2 px-3 ' +
                        (isFirstField
                          ? 'border-l-2 border-darkGray border-opacity-60'
                          : 'border-l border-darkGray border-opacity-20')
                      }
                      style={{ backgroundColor: letterData?.hex }}
                      align="center"
                    >
                      {ratingLetter as string}
                    </td>
                  );
                }),
              ),
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
