import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { GeneralRating } from '../../api/types';
import { Loader } from '../../components/Loader/Loader';
import { Table } from '../../components/Table';
import { useGeneralRatings } from '../../hooks/api/useGeneralRatings';
import { useReuters } from '../../hooks/api/useReuters';
import { formatDate } from '../../utils/formatDate';

type GeneralRatingWithCount = GeneralRating & { company_count: number };
type CountedGeneralRating = Record<string, GeneralRatingWithCount>;

export const Ratings = () => {
  const { generalRatings, isLoading } = useGeneralRatings();

  const { reuters, isLoading: isReutersLoading } = useReuters();
  const countedRatings = useMemo(() => countRatings(generalRatings), [generalRatings]);

  return (
    <div>
      <Table
        isSortable={true}
        cols={['Название рейтинга', 'Год', 'Рейтер', 'Количество компаний']}
        rows={Object.entries(countedRatings)?.map(([rating_name, rating]) => [
          {
            cell: (
              <Link to={String(rating.id)} key={rating_name} className="text-main hover:underline">
                {rating_name}
              </Link>
            ),
            sortValue: rating_name?.toLowerCase(),
          },
          { cell: formatDate(rating.calculated_on), sortValue: rating.calculated_on },
          {
            cell: reuters?.find((__) => __.id === rating.reuter_id)?.reuter_name,
            sortValue: reuters?.find((__) => __.id === rating.reuter_id)?.reuter_name,
          },
          { cell: rating.company_count, sortValue: rating.company_count },
        ])}
      />
      {isLoading || isReutersLoading ? <Loader /> : null}
    </div>
  );
};

const countRatings = (ratings?: GeneralRating[]): CountedGeneralRating => {
  const countedRatings: CountedGeneralRating = {};

  for (const rating of ratings || []) {
    const rating_name = rating.rating_name || '';
    if (countedRatings[rating_name]) {
      countedRatings[rating_name].company_count!++;
    } else {
      countedRatings[rating_name] = { ...rating, company_count: 1 };
    }
  }

  return countedRatings;
};
