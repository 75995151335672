import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { Chart, type ChartOptions } from 'react-charts';
import { useParams } from 'react-router-dom';
import { getEthicsRatingsByInn } from '../../api/requests';
import { RatingEthics } from '../../api/types';
import { Heading } from '../../components/Heading';
import { Loader } from '../../components/Loader/Loader';
import { Table } from '../../components/Table';
import { useCompanyData } from '../../hooks/api/useCompanyData';
import { CompanyESGData } from './CompanyESGData';

export const Company = () => {
  const { companyId } = useParams();

  const { companyData, industryData } = useCompanyData(companyId);

  const { data: ratingsEthics, isLoading: isEthicsLoading } = useQuery({
    queryKey: ['ratingsEthics', companyData?.inn],
    queryFn: async () => await getEthicsRatingsByInn(companyData?.inn || ''),
    enabled: !!companyData?.inn,
  });

  const chartDataEthics: ChartOptions<RatingEthics> = useMemo(
    () => ({
      data:
        ratingsEthics?.map((_) => ({
          label: String(_.year_rating_ethics_tab?.index_simple?.toFixed(4) || 0),
          data: ratingsEthics
            .sort((a, b) => (a?.id || 0) - (b?.id || 0))
            .map((_, i) => ({ ..._, id: i + 1 })),
        })) || [],
      primaryAxis: {
        getValue: (data) => data.id,
        scaleType: 'linear',
        formatters: {
          scale: () => '',
          tooltip: () => '',
        },
      },
      secondaryAxes: [
        {
          getValue: (data) => Number(data.year_rating_ethics_tab?.index_simple?.toFixed(4) || 0),
        },
      ],
    }),
    [ratingsEthics],
  );

  return (
    <div>
      <Heading className="mb-6">{companyData?.company_name}</Heading>
      <div className="mb-10">
        {companyData?.inn}, {industryData?.industry_name}
      </div>
      <div className="mb-10">
        <CompanyESGData inn={companyData?.inn} />
      </div>
      <div className="mb-10">
        <Heading size="S">Индекс этичности</Heading>
        <Table
          isSortable={true}
          cols={['Год', 'Индекс', 'Кол-во обзоров (негативных/нейтральных/позитивных)']}
          rows={ratingsEthics?.map((_, i) => [
            { cell: _.year, sortValue: _.year },
            {
              cell: _.year_rating_ethics_tab?.index_simple?.toFixed(4),
              sortValue: _.year_rating_ethics_tab?.index_simple,
            },
            {
              cell: (
                <div key={i}>
                  <span className="text-rating-c">
                    {_.year_rating_ethics_tab?.negative_reviews_count || '-'}
                  </span>
                  /
                  <span className="text-rating-ccc">
                    {_.year_rating_ethics_tab?.neutral_reviews_count || '-'}
                  </span>
                  /
                  <span className="text-rating-aaa">
                    {_.year_rating_ethics_tab?.positive_reviews_count || '-'}
                  </span>
                </div>
              ),
              sortValue:
                (_.year_rating_ethics_tab?.negative_reviews_count || 0) +
                (_.year_rating_ethics_tab?.neutral_reviews_count || 0) +
                (_.year_rating_ethics_tab?.positive_reviews_count || 0),
            },
          ])}
        />
        {isEthicsLoading ? <Loader /> : null}
        {!ratingsEthics?.length && !isEthicsLoading ? (
          <Heading className="italic font-normal text-center text-gray" size="XS">
            Данные индекса этичности отсутствуют
          </Heading>
        ) : null}
        {chartDataEthics?.data?.length && ratingsEthics?.length ? (
          <div className="h-[200px] mt-10">
            <Chart options={chartDataEthics} />
          </div>
        ) : null}
      </div>
    </div>
  );
};
