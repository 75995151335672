import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { getEthicsRatingsByIdList } from '../../api/requests';
import { GeneralRating } from '../../api/types';
import { Loader } from '../../components/Loader/Loader';
import { Table } from '../../components/Table';
import { useGeneralRatings } from '../../hooks/api/useGeneralRatings';

interface RatingsEthicsTable {
  rating?: GeneralRating;
}

export const RatingsEthicsTable = ({ rating }: RatingsEthicsTable) => {
  const { generalRatings, isLoading } = useGeneralRatings();

  const filteredGeneralRatingIds = useMemo(() => {
    const idList = [];
    for (const generalRating of generalRatings || []) {
      if (generalRating.rating_name === rating?.rating_name) {
        idList.push(generalRating.ethics_rating_tab_id || 0);
      }
    }
    return idList;
  }, [generalRatings, rating?.rating_name]);

  const { data: ratingsEthics, isLoading: isLoadingEthics } = useQuery({
    queryKey: ['ratingsEthics', rating?.rating_name],
    queryFn: async () => await getEthicsRatingsByIdList(filteredGeneralRatingIds),
    enabled: !!filteredGeneralRatingIds,
  });

  return (
    <div>
      {ratingsEthics?.length ? (
        <>
          <Table
            isSortable={true}
            cols={[
              'Компания',
              'Год',
              'Индекс',
              'Кол-во обзоров (негативных/нейтральных/позитивных)',
            ]}
            rows={ratingsEthics?.map((_, i) => [
              {
                cell: (
                  <Link
                    to={`/companies/${_.year_rating_ethics_tab?.company?.id}`}
                    key={i + '-cmp-name'}
                    className="text-main hover:underline"
                  >
                    {_.year_rating_ethics_tab?.company?.company_name}
                  </Link>
                ),
                sortValue: _.year_rating_ethics_tab?.company?.company_name?.toLowerCase(),
              },
              { cell: _.year, sortValue: _.year },
              {
                cell: _.year_rating_ethics_tab?.index_simple?.toFixed(4),
                sortValue: _.year_rating_ethics_tab?.index_simple,
              },
              {
                cell: (
                  <div key={i} className="text-gray">
                    <span className="text-rating-c">
                      {_.year_rating_ethics_tab?.negative_reviews_count || '-'}
                    </span>
                    /
                    <span className="text-rating-ccc">
                      {_.year_rating_ethics_tab?.neutral_reviews_count || '-'}
                    </span>
                    /
                    <span className="text-rating-aaa">
                      {_.year_rating_ethics_tab?.positive_reviews_count || '-'}
                    </span>
                  </div>
                ),
                sortValue:
                  (_.year_rating_ethics_tab?.negative_reviews_count || 0) +
                  (_.year_rating_ethics_tab?.neutral_reviews_count || 0) +
                  (_.year_rating_ethics_tab?.positive_reviews_count || 0),
              },
            ])}
          />
        </>
      ) : null}
      {isLoadingEthics || isLoading ? <Loader /> : null}
    </div>
  );
};
