import { useQuery } from '@tanstack/react-query';
import { getIndustries } from '../../api/requests';

export const useIndustries = () => {
  const { data: industries, isLoading } = useQuery({
    queryKey: ['industries'],
    queryFn: async () => {
      const data = await getIndustries();
      return data?.sort((a, b) => {
        if (!a.industry_name || !b.industry_name) {
          return 0;
        }

        if (a.industry_name > b.industry_name) {
          return 1;
        }
        if (a.industry_name < b.industry_name) {
          return -1;
        }
        return 0;
      });
    },
  });

  return { industries, isLoading };
};
