import { useQuery } from '@tanstack/react-query';
import { getRegions } from '../../api/requests';

export const useRegions = () => {
  const { data: regions, isLoading } = useQuery({
    queryKey: ['regions'],
    queryFn: async () => {
      const data = await getRegions();
      return data?.sort((a, b) => {
        if (!a.region_name || !b.region_name) {
          return 0;
        }

        if (a.region_name > b.region_name) {
          return 1;
        }
        if (a.region_name < b.region_name) {
          return -1;
        }
        return 0;
      });
    },
  });

  return { regions, isLoading };
};
