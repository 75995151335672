import { useQuery } from '@tanstack/react-query';
import { getLettersData } from '../../api/requests';
import { type LetterData } from '../../api/types';

export const useLettersData = (): LetterData[] => {
  const { data: lettersData } = useQuery({
    queryKey: ['lettersData'],
    queryFn: getLettersData,
  });

  return lettersData || [];
};
