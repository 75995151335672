import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { Chart, type ChartOptions } from 'react-charts';
import { getESGRatingsByInn, getReuterModels } from '../../api/requests';
import { type RatingESG, type Reuter, type ReuterModel } from '../../api/types';
import { Heading } from '../../components/Heading';
import { Loader } from '../../components/Loader/Loader';
import { useGeneralRatings } from '../../hooks/api/useGeneralRatings';
import { useLettersData } from '../../hooks/api/useLettersData';
import { useReuters } from '../../hooks/api/useReuters';
import { CompanyESGDataTable } from './CompanyESGDataTable';

interface CompanyESGDataProps {
  inn?: string;
}

interface ReuterWithModel extends Reuter {
  model?: ReuterModel;
}

interface RatingESGWithReuterModel extends RatingESG {
  reuter_model_id?: number;
}

export const CompanyESGData = ({ inn }: CompanyESGDataProps) => {
  const { data: ratingsEsg, isLoading: isESGLoading } = useQuery({
    queryKey: ['ratingsEsg', inn],
    queryFn: async () => {
      const data = await getESGRatingsByInn(inn || '');
      return data?.sort((a, b) => (a?.year || 0) - (b?.year || 0));
    },
    enabled: !!inn,
  });

  const { data: reuterModels, isLoading: isReuterModelsLoading } = useQuery({
    queryKey: ['reuterModels'],
    queryFn: getReuterModels,
  });

  const { reuters, isLoading: isReutersLoading } = useReuters();
  const { generalRatings } = useGeneralRatings();
  const lettersData = useLettersData();

  const reutersWithModels = getReutersWithModels(reuters, reuterModels);
  const ratingsESGWithModelId: RatingESGWithReuterModel[] = [];

  ratingsEsg?.forEach((rating) => {
    const generalRating = generalRatings?.find((_) => _.esg_rating_tab_id === rating.id);
    ratingsESGWithModelId.push({ ...rating, reuter_model_id: generalRating?.reuter_model_id });
  });

  const chartDataESG: ChartOptions<RatingESG> = useMemo(
    () => ({
      data:
        reutersWithModels?.map((_) => ({
          label:
            (_.reuter_name || '') +
            (_.model?.rating_model_name ? ' - ' + _.model?.rating_model_name : ''),
          data:
            ratingsESGWithModelId
              ?.sort((a, b) => (a.year || 0) - (b.year || 0))
              .filter(
                (__) =>
                  __.reuter_id === _.id && (_.model?.id ? _.model.id === __.reuter_model_id : true),
              ) || [],
        })) || [],
      primaryAxis: {
        getValue: (data) => data.year || 0,
        scaleType: 'band',
      },
      secondaryAxes: [
        {
          getValue: (data) =>
            lettersData?.find(
              (_) =>
                _.letter_esg === data.year_rating_esg_tab?.esg_letter ||
                _.letter_esg === data.year_rating_esg_tab?.range,
            )?.rating_esg_index,
          formatters: {
            scale: (data: number) =>
              lettersData?.find((_) => _.rating_esg_index === data)?.letter_esg || '',
            tooltip: (data: number) => (
              <div>{lettersData?.find((_) => _.rating_esg_index === data)?.letter_esg || ''}</div>
            ),
          },
          min: 0,
        },
      ],
    }),
    [reuters, ratingsEsg, lettersData],
  );

  return (
    <div>
      <Heading size="S">Рейтинги ESG</Heading>
      <CompanyESGDataTable ratingsEsg={ratingsEsg} reuters={reuters} reuterModels={reuterModels} />
      {isESGLoading || isReutersLoading || isReuterModelsLoading ? <Loader /> : null}
      {!ratingsEsg?.length && !isESGLoading ? (
        <Heading className="italic font-normal text-center text-gray" size="XS">
          Данные рейтинга ESG отсутствуют
        </Heading>
      ) : null}
      {chartDataESG?.data?.length && ratingsEsg?.length ? (
        <div className="h-[200px] mt-10">
          <Chart options={chartDataESG} />
        </div>
      ) : null}
    </div>
  );
};

const getReutersWithModels = (
  reuters?: Reuter[],
  reuterModels?: ReuterModel[],
): ReuterWithModel[] => {
  const reutersWithModels: ReuterWithModel[] = [];
  reuters?.forEach((reuter) => {
    const models = reuterModels?.filter((_) => _.reuter_id === reuter.id);
    if (models?.length) {
      models.forEach((model) => reutersWithModels.push({ ...reuter, model }));
    }
    reutersWithModels.push({ ...reuter });
  });

  return reutersWithModels;
};
