import { useQuery } from '@tanstack/react-query';
import { getReuters } from '../../api/requests';

export const useReuters = () => {
  const { data: reuters, isLoading } = useQuery({
    queryKey: ['reuters'],
    queryFn: getReuters,
  });

  return { reuters, isLoading };
};
