import React from 'react';

interface LoaderProps {
  size?: 'L' | 'S';
}

export const Loader = ({ size = 'L' }: LoaderProps) => (
  <div className={`flex items-center justify-center ${size === 'L' ? 'mt-10' : null}`}>
    <div
      className={`animate-spin border-main border-r-0 border-l-0 border-t-0 rounded-full ${size === 'L' ? 'border-4 h-14 w-14' : 'border-2 h-5 w-5'}`}
    />
  </div>
);
