import { useQuery } from '@tanstack/react-query';
import { useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { getCompanies, getESGRatingsByIdList } from '../../api/requests';
import { GeneralRating } from '../../api/types';
import { Loader } from '../../components/Loader/Loader';
import { Table } from '../../components/Table';
import { useCompanies } from '../../hooks/api/useCompanies';
import { useGeneralRatings } from '../../hooks/api/useGeneralRatings';
import { useIndustries } from '../../hooks/api/useIndustries';
import { useLettersData } from '../../hooks/api/useLettersData';
import { useRegions } from '../../hooks/api/useRegions';

interface RatingsESGTableProps {
  rating?: GeneralRating;
}

export const RatingsESGTable = ({ rating }: RatingsESGTableProps) => {
  const [selectedIndustries, setSelectedIndustries] = useState<number[]>([]);
  const [selectedRegions, setSelectedRegions] = useState<number[]>([]);

  const lettersData = useLettersData();
  const { generalRatings, isLoading } = useGeneralRatings();
  const { companies } = useCompanies();
  const { regions } = useRegions();
  const { industries } = useIndustries();

  const filteredGeneralRatingIds = useMemo(() => {
    const idList = [];
    for (const generalRating of generalRatings || []) {
      if (generalRating.rating_name === rating?.rating_name) {
        idList.push(generalRating.esg_rating_tab_id || 0);
      }
    }
    return idList;
  }, [generalRatings, rating?.rating_name]);

  const { data: ratingsEsg, isLoading: isLoadingESG } = useQuery({
    queryKey: ['ratingsEsg', rating?.rating_name],
    queryFn: async () => await getESGRatingsByIdList(filteredGeneralRatingIds),
    enabled: !!filteredGeneralRatingIds,
  });

  const letterToCell = useCallback(
    (key?: string, letter?: string) => {
      if (!letter) {
        return {};
      }

      const letterData = lettersData?.find((_) => _.letter_esg === letter);

      return {
        cell: <div key={key}>{letter}</div>,
        sortValue: letterData?.rating_esg_index,
        style: { backgroundColor: letterData?.hex },
      };
    },
    [lettersData],
  );

  const filteredIndustries = useMemo(
    () =>
      industries?.map((_) => ({
        value: _.id,
        label: (_.industry_name || '') + ' - ' + (_.sub_industry_name || ''),
      })),
    [industries],
  );

  const filteredRegions = useMemo(
    () =>
      regions?.map((_) => ({
        value: _.id,
        label: _.region_name,
      })),
    [regions],
  );

  const filteredCompanies = useMemo(() => {
    if (selectedIndustries.length && !selectedRegions.length) {
      return companies?.filter(
        (_) =>
          selectedIndustries.find((__) => __ === _.industry?.id) &&
          ratingsEsg?.find((__) => __.year_rating_esg_tab?.company?.id === _.id),
      );
    }

    if (!selectedIndustries.length && selectedRegions.length) {
      return companies?.filter(
        (_) =>
          selectedRegions.find((__) => __ === _.region_id) &&
          ratingsEsg?.find((__) => __.year_rating_esg_tab?.company?.id === _.id),
      );
    }

    if (selectedIndustries.length && selectedRegions.length) {
      return companies?.filter(
        (_) =>
          selectedRegions.find((__) => __ === _.region_id) &&
          selectedIndustries.find((__) => __ === _.industry?.id) &&
          ratingsEsg?.find((__) => __.year_rating_esg_tab?.company?.id === _.id),
      );
    }

    return companies?.filter((_) =>
      ratingsEsg?.find((__) => __.year_rating_esg_tab?.company?.id === _.id),
    );
  }, [selectedIndustries, selectedRegions, companies, ratingsEsg]);

  return (
    <div>
      <Select
        options={filteredIndustries}
        className="mb-4"
        isMulti
        placeholder="Выбор отрасли"
        onChange={(e) => setSelectedIndustries(e.map((_) => _.value || 0))}
      />
      <Select
        options={filteredRegions}
        className="mb-4"
        isMulti
        placeholder="Выбор региона"
        onChange={(e) => setSelectedRegions(e.map((_) => _.value || 0))}
      />
      {ratingsEsg?.length ? (
        <Table
          isSortable={true}
          cols={['Компания', 'Отрасль', 'Регион', 'E', 'S', 'G', 'ESG']}
          rows={ratingsEsg
            ?.filter((rating) =>
              filteredCompanies?.find(
                (company) => rating?.year_rating_esg_tab?.company?.id === company.id,
              ),
            )
            .map(({ year_rating_esg_tab }, i) => {
              const company = filteredCompanies?.find(
                (_) => _.id === year_rating_esg_tab?.company?.id,
              );
              const region = regions?.find((_) => _.id === company?.region_id)?.region_name;
              return [
                {
                  cell: (
                    <Link
                      to={`/companies/${company?.id}`}
                      key={`${i}-name`}
                      className="text-main hover:underline"
                    >
                      {company?.company_name}
                    </Link>
                  ),
                  sortValue: company?.company_name?.toLowerCase(),
                },
                {
                  cell: company?.industry?.industry_name || '-',
                  sortValue: company?.industry?.industry_name,
                },
                { cell: region, sortValue: region },
                letterToCell(`${company?.id}-e`, year_rating_esg_tab?.e_letter),
                letterToCell(`${company?.id}-s`, year_rating_esg_tab?.s_letter),
                letterToCell(`${company?.id}-g`, year_rating_esg_tab?.g_letter),
                letterToCell(`${company?.id}-esg`, year_rating_esg_tab?.esg_letter),
              ];
            })}
        />
      ) : null}
      {isLoadingESG || isLoading ? <Loader /> : null}
    </div>
  );
};
