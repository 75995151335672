import { useQuery } from '@tanstack/react-query';
import { getGeneralRatings } from '../../api/requests';

export const useGeneralRatings = () => {
  const { data: generalRatings, isLoading } = useQuery({
    queryKey: ['ratings'],
    queryFn: getGeneralRatings,
  });

  return { generalRatings, isLoading };
};
