import { ChangeEvent, useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useNavigate } from 'react-router-dom';
import { login } from '../../api/requests';
import { Button } from '../../components/Button';
import { Heading } from '../../components/Heading';
import { Input } from '../../components/Input';
import { Logo } from '../../components/Logo';

interface LoginData {
  email: string;
  password: string;
}

export function Login() {
  const [loginData, setLoginData] = useState<LoginData>({ email: '', password: '' });
  const navigate = useNavigate();
  const captchaRef = useRef<ReCAPTCHA>(null);

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (e.key === 'Enter') handleLogin();
    };

    document.addEventListener('keypress', listener);

    return () => document.removeEventListener('keypress', listener);
  });

  const handleLogin = async () => {
    if (loginData?.email && loginData.password) {
      const token = captchaRef.current?.getValue();
      //if (!token) return;

      await login({ ...loginData });
      navigate('/');
    }
  };

  const handleChangeLoginData = (e: ChangeEvent<HTMLInputElement>) => {
    const { type, value } = e.target;
    setLoginData({ ...loginData, [type]: value });
  };

  return (
    <div className="bg-main flex justify-center content-center items-center h-screen">
      <div className="bg-blue flex flex-col items-center px-[30px] py-[40px] border border-darkGray border-opacity-40 shadow-xl rounded-[16px] w-[380px]">
        <Logo version="transparent" size={60} className="mb-[20px]" />
        <Heading className="self-start" size="S">
          Вход в Личный кабинет
        </Heading>
        <Input
          className="w-full"
          type="email"
          placeholder="Почта"
          value={loginData?.email}
          onChange={handleChangeLoginData}
          validationRegExp={
            /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
          }
          errorMessage="Неверный Email"
        />
        <Input
          type="password"
          placeholder="Пароль"
          className="w-full mt-2"
          value={loginData?.password}
          onChange={handleChangeLoginData}
        />
        <ReCAPTCHA
          sitekey="6LfaxAcnAAAAAAlWDPPcbCnNl5BMhHaKHIfrQCpq"
          ref={captchaRef}
          className="mb-2"
        />
        <Button className="w-full" onClick={handleLogin}>
          Войти
        </Button>
      </div>
    </div>
  );
}
