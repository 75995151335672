import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useParams } from 'react-router-dom';
import MaskedTextInput from 'react-text-mask';
import { getNewsByInn } from '../../api/requests';
import { Heading } from '../../components/Heading';
import { Loader } from '../../components/Loader/Loader';
import { Table } from '../../components/Table';
import { useCompanyData } from '../../hooks/api/useCompanyData';
import { formatDate } from '../../utils/formatDate';

const MAX_TEXT_LENGTH = 70;

export const News = () => {
  const { companyId } = useParams();
  const { companyData, industryData } = useCompanyData(companyId);

  const [startMonth, setStartMonth] = useState<Date | null>();
  const [endMonth, setEndMonth] = useState<Date | null>();

  const [expandedMessageId, setExpandedMessageId] = useState<number>(-1);

  const { data: allNews, isLoading } = useQuery({
    queryKey: ['news', companyData?.inn],
    queryFn: async () => await getNewsByInn(companyData?.inn || ''),
    enabled: !!companyData?.inn,
  });

  const filteredNews = useMemo(() => {
    return allNews?.filter((_) => {
      if (!_.parsed_on) {
        return true;
      }

      const date = new Date(_.parsed_on || '');

      return (!startMonth || date >= startMonth) && (!endMonth || date <= endMonth);
    });
  }, [startMonth, endMonth, allNews]);

  return (
    <div>
      <Heading className="mb-6">{companyData?.company_name}</Heading>
      <Heading size="S" className="mb-10">
        {companyData?.inn}, {industryData?.industry_name}
      </Heading>
      <div className="flex gap-12 mb-10 text-primary-text">
        <div>
          <div className="select-none text-secondary-text">Месяц начала</div>
          <DatePicker
            showMonthYearPicker
            value={startMonth ? moment(startMonth).format('MM.YYYY') : undefined}
            placeholderText="__.____"
            onChange={setStartMonth}
            customInput={
              <MaskedTextInput type="text" mask={[/\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]} />
            }
            className="px-4 py-2.5 text-base border border-darkGray border-opacity-40 rounded"
          />
        </div>
        <div>
          <div className="select-none text-secondary-text">Месяц конца</div>
          <DatePicker
            showMonthYearPicker
            value={endMonth ? moment(endMonth).format('MM.YYYY') : undefined}
            placeholderText="__.____"
            onChange={setEndMonth}
            customInput={
              <MaskedTextInput type="text" mask={[/\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]} />
            }
            className="px-4 py-2.5 text-base border border-darkGray border-opacity-40 rounded"
          />
        </div>
      </div>
      <Table
        className="transition-all"
        alignTop={true}
        isSortable={true}
        cols={['Дата парсинга', 'Заголовок', 'Текст', 'Метаданные (источник)']}
        rows={filteredNews?.map((_, i) => [
          { cell: formatDate(_.parsed_on), sortValue: _.parsed_on },
          { cell: _.header, sortValue: _.header?.toLowerCase() },
          {
            cell: (
              <div key={i + '-text'}>
                {expandedMessageId === _.id
                  ? _.text
                  : _.text?.substring(0, MAX_TEXT_LENGTH)?.trim() + '...'}
                <button
                  className="text-main hover:underline ml-1"
                  onClick={
                    expandedMessageId === _.id
                      ? () => setExpandedMessageId(-1)
                      : () => setExpandedMessageId(_.id || -1)
                  }
                >
                  {expandedMessageId === _.id ? 'Свернуть' : 'Развернуть'}
                </button>
              </div>
            ),
            sortValue: _.text?.toLowerCase(),
          },
          { cell: _.metainfo, sortValue: _.metainfo?.toLowerCase() },
        ])}
      />
      {isLoading ? <Loader /> : null}
      {!!allNews?.length && !filteredNews?.length ? (
        <Heading className="italic font-normal text-center text-gray" size="XS">
          Данные по новостям о компании за выбранное время отсутствуют. Попробуйте изменить период
          фильтрации.
        </Heading>
      ) : null}
      {!allNews?.length && !isLoading ? (
        <Heading className="italic font-normal text-center text-gray" size="XS">
          Данные по новостям о компании отсутствуют
        </Heading>
      ) : null}
    </div>
  );
};
