import { useQuery } from '@tanstack/react-query';
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { getCompanies, getIndustries, getRegions } from '../../api/requests';
import { Loader } from '../../components/Loader/Loader';
import { Table } from '../../components/Table';
import { useCompanies } from '../../hooks/api/useCompanies';
import { useIndustries } from '../../hooks/api/useIndustries';
import { useRegions } from '../../hooks/api/useRegions';

export const Companies = () => {
  const [selectedIndustries, setSelectedIndustries] = useState<number[]>([]);
  const [selectedRegions, setSelectedRegions] = useState<number[]>([]);

  const { companies, isLoading } = useCompanies();
  const { regions } = useRegions();
  const { industries } = useIndustries();

  const filteredIndustries = useMemo(
    () =>
      industries?.map((_) => ({
        value: _.id,
        label: (_.industry_name || '') + ' - ' + (_.sub_industry_name || ''),
      })),
    [industries],
  );

  const filteredRegions = useMemo(
    () =>
      regions?.map((_) => ({
        value: _.id,
        label: _.region_name,
      })),
    [regions],
  );

  const filteredCompanies = useMemo(() => {
    if (selectedIndustries.length && !selectedRegions.length) {
      return companies?.filter((_) => selectedIndustries.find((__) => __ === _.industry?.id));
    }

    if (!selectedIndustries.length && selectedRegions.length) {
      return companies?.filter((_) => selectedRegions.find((__) => __ === _.region_id));
    }

    if (selectedIndustries.length && selectedRegions.length) {
      return companies?.filter(
        (_) =>
          selectedRegions.find((__) => __ === _.region_id) &&
          selectedIndustries.find((__) => __ === _.industry?.id),
      );
    }

    return companies;
  }, [selectedIndustries, selectedRegions, companies]);

  return (
    <div>
      <Select
        options={filteredIndustries}
        className="mb-4"
        isMulti
        placeholder="Выбор отрасли"
        onChange={(e) => setSelectedIndustries(e.map((_) => _.value || 0))}
      />
      <Select
        options={filteredRegions}
        className="mb-4"
        isMulti
        placeholder="Выбор региона"
        onChange={(e) => setSelectedRegions(e.map((_) => _.value || 0))}
      />
      <Table
        isSortable={true}
        cols={['Название компании', 'ИНН', 'Отрасль', 'Регион', '', '', '']}
        rows={filteredCompanies?.map(({ id, company_name, inn, industry, region_id }, i) => {
          const region = regions?.find((_) => _.id === region_id)?.region_name;
          return [
            {
              cell: (
                <Link to={String(id)} key={i} className="text-main hover:underline">
                  {company_name}
                </Link>
              ),
              sortValue: company_name?.toLowerCase(),
            },
            { cell: inn, sortValue: inn },
            { cell: industry?.industry_name, sortValue: industry?.industry_name?.toLowerCase() },
            { cell: region, sortValue: region },
            {
              cell: (
                <Link to={`/reports/${String(id)}`} key={i} className="text-main hover:underline">
                  Отчёты
                </Link>
              ),
            },
            {
              cell: (
                <Link to={`/news/${String(id)}`} key={i} className="text-main hover:underline">
                  Новости
                </Link>
              ),
            },
            {
              cell: (
                <Link
                  to={`/website/${String(id)}`}
                  key={i}
                  className="text-main hover:underline whitespace-nowrap"
                >
                  Веб-сайт
                </Link>
              ),
            },
          ];
        })}
      />
      {isLoading ? <Loader /> : null}
    </div>
  );
};
